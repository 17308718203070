import './Row.scss';
import React, { ReactNode } from 'react';

export interface RowProps {
  children?: ReactNode;
  index: number;
  style?: object;
  classes?: string;
}

export function Row({ children, index, style, classes }: RowProps) {
  return (
    <tr
      className={`row-container ${classes} ${
        index % 2 === 1 ? 'row-alternate-bg' : ''
      }`}
      style={style}
    >
      {children}
    </tr>
  );
}
