import * as React from 'react';

import './ContentPanel.scss';
import LandingPage from '../Authentication/landingPage/LandingPage';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';

export const ContentPanel = () => {
  const { token } = React.useContext(GlobalUserContext);
  return (
    <div className="content-panel-container">
      {token ? <LandingPage /> : null}
    </div>
  );
};
