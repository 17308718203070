import { Button } from '../../../components/Button/Button';
import { InfusionNoteDTO } from '.';
import './InfusionNotes.scss';
import './SendNoteControls.scss';
import { Edit } from '../../../../src/icons/Edit';
import { useEffect, useState } from 'react';
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { WysiwygEditor } from '../../../../src//components/WysiwygEditor/WysiwygEditor';
import { LoadingSpinner } from '../../../components/common/loader/LoadingSpinner';
import { Trash } from '../../../icons/Trash';
import { InfusionNoteDeleteVerify } from './InfusionNoteDeleteVerify/InfusionNoteDeleteVerify';
import { Floppy } from '../../../icons/Floppy';

export interface InfusionNoteProps {
  data: InfusionNoteDTO;
  isLoadingEditNote: boolean;
  onNoteEdit?: (noteId: string, noteBody: string) => Promise<boolean>;
  onNoteDelete?: (noteId: string) => Promise<boolean>;
}

export function InfusionNote({
  data,
  isLoadingEditNote,
  onNoteEdit,
  onNoteDelete,
}: InfusionNoteProps) {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const { key, author, date, time, editedDateTime, encrypt_note } =
    data;
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [prevEditorState, setPrevEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [deleteVerify, setDeleteVerify] = useState(false);

  const sendable = editorState.getCurrentContent().hasText();
  const handleEditClick = () => {
    if (!isEditing) {
      setPrevEditorState(editorState);
    }
    setIsEditing(!isEditing);
  };

  const handleSubmitClick = async () => {
    if (!onNoteEdit || isLoadingEditNote) return;
    await onNoteEdit(
      key,
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );

    setIsEditing(false);
  };

  const handleCancelClick = () => {
    if (isLoadingEditNote) return;
    setEditorState(prevEditorState);
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setDeleteVerify(true);
  };

  const handleDeleteVerifyCancel = () => {
    if (!isDeleting) setDeleteVerify(false);
  };

  const handleDeleteVerifyOk = async () => {
    if (!onNoteDelete || isDeleting) return;
    setIsDeleting(true);

    await onNoteDelete(key);
    setDeleteVerify(false);
    setIsDeleting(false);
  };

  useEffect(() => {
    try {
      // Attempt to parse this as draft-js raw content.
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(encrypt_note))
        )
      );
    } catch (e) {
      // if an error occurs in parsing, this is probably just text and will cause draft-js to crash, so parse it as html
      const blocksFromHTML = convertFromHTML(encrypt_note);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(content));
    }
  }, [encrypt_note]);

  const EditorControls = (
    <div className="edit-controls">
      <Button
        icon={
          isLoadingEditNote ? (
            <LoadingSpinner width={30} height={30} color={'white'} />
          ) : (
            <Floppy />
          )
        }
        iconPlacement="left"
        className={`send-button ${sendable ? 'active' : 'disabled'}`}
        label={!isLoadingEditNote ? 'Save' : ''}
        onClick={handleSubmitClick}
      />
      <Button
        className={`cancel-button`}
        label="Cancel"
        onClick={handleCancelClick}
      />
    </div>
  );

  return (
    <div
      key={key}
      className={`notes-item ${isEditing ? 'highlighted' : ''}`}
    >
      <div className="note-header">
        <div className="note-header-left">
          <div className="note-author">{author}</div>
          <div className="note-date">{date}</div>
          <div className="note-time">{time}</div>
          <div
            className={
              editedDateTime ? 'note-edited' : 'note-not-edited'
            }
          >
            (edited)
            <span className="note-edited-tooltip">
              {editedDateTime}
            </span>
          </div>
        </div>
        <div>
          {!isEditing && (
            <Button
              className={`control-button ${
                isEditing ? 'highlighted' : ''
              }`}
              icon={<Edit />}
              onClick={handleEditClick}
              width={'40px'}
              height={'40px'}
            />
          )}
          {isEditing && (
            <Button
              className={`control-button ${
                isEditing ? 'highlighted' : ''
              }`}
              icon={<Trash />}
              onClick={handleDeleteClick}
              width={'40px'}
              height={'40px'}
            />
          )}
        </div>
      </div>
      <div className="note-body">
        <WysiwygEditor
          readOnly={!isEditing}
          editorState={editorState}
          setEditorState={setEditorState}
        />
        {isEditing && EditorControls}
        {/* {body} */}
      </div>
      {deleteVerify && (
        <InfusionNoteDeleteVerify
          isDeleting={isDeleting}
          onOkClick={handleDeleteVerifyOk}
          onCancelClick={handleDeleteVerifyCancel}
        />
      )}
    </div>
  );
}
