import React from "react";
import clsx from 'clsx'
import { ButtonProps } from "../../shared/types/types";
import "./button.scss";

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  label,
  height,
  width,
  onClick,
  disabled,
  icon,
  className,
  iconPlacement,
  ...props
}: ButtonProps) => {
  return (
    <button
      type="button"
      className={clsx('button', disabled && 'disabled', className)}
      onClick={onClick}
      disabled={disabled}
      style={{ width: width, height: height, ...props }}
      {...props}
    >
      {iconPlacement === "left" && icon}
      {label && label}
      {!iconPlacement && icon && icon}
      {iconPlacement === "right" && icon}
    </button>
  );
};
