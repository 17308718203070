import { useContext, useState, useEffect, useCallback } from 'react';
import { FilterButton } from '../../../common/buttons/iconButtons/FilterButton';
import {
  SortButton,
  SortDirection,
} from '../../../common/buttons/iconButtons/SortButton';
import { Header } from '../../../common/table/Header/Header';
import { HeaderCell } from '../../../common/table/HeaderCell/HeaderCell';
import { Title } from '../../../Title/Title';
import { ToggleExpand } from '../../../ToggleExpand/ToggleExpand';
import './PatientList.scss';
import { Row } from '../../../common/table/Row/Row';
import { Cell } from '../../../common/table/Cell/Cell';
import { Search } from '../../../common/search/Search';
import { ResetFiltersButton } from './ResetFiltersButton';
import { Link } from 'react-router-dom';
import { PrivacyContext } from '../../../../shared/contexts/PrivacyContext';
import { fetchAuthenticated } from '../../../../controllers';
import { CAS_BACK_END_API_URL } from '../../../../constants';
import { convertDateTime } from '../../../common/convertDateTime/convertDateTime';
import { GlobalUserContext } from '../../../../shared/contexts/GlobalUserContext';
import { PatientRecord } from '../../../../shared/types/types';
import { Privacy } from '../../../Privacy/Privacy';
import { decryptObject } from '../../../Encryption/encryptionHandler';

export interface PatientListProps {}

export interface PatientsServiceResult {
  status: string;
  executionTime?: number;
  patients: Array<PatientRecord>;
  errors: string[];
}
export interface PatientsAjaxResult {
  result: PatientsServiceResult;
}

type InfusionFilter = 'none' | 'exclude_na' | 'only_na';

export function PatientList(props: PatientListProps) {
  const { setPatientInfo } = useContext(GlobalUserContext);
  const [open, setOpen] = useState(true);
  const [patList, setPatList] = useState<PatientRecord[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [infusionFilter, setInfusionFilter] =
    useState<InfusionFilter>('none');
  const [sortDirectionFirstName, setSortDirectionFirstName] =
    useState<SortDirection>('none');
  const [sortDirectionLastName, setSortDirectionLastName] =
    useState<SortDirection>('none');
  const [sortDirectionMedicalId, setSortDirectionMedicalId] =
    useState<SortDirection>('none');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiData, setApiData] = useState<PatientsAjaxResult>();
  const [serverError, setServerError] = useState();

  const { token } = useContext(GlobalUserContext);

  const getPatientList = useCallback(async () => {
    const url = CAS_BACK_END_API_URL + '/patients';
    const config: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setIsLoading(true);
    const response = await fetchAuthenticated<PatientsAjaxResult>(
      url,
      config
    );
    setIsLoading(false);

    if (response.ok) {
      setApiData(response.parsedBody);
    }
  }, [token]);

  useEffect(() => {
    try {
      getPatientList();
    } catch (error: any) {
      setServerError(error);
    }
  }, [getPatientList]);

  useEffect(() => {
    if (apiData?.result?.patients) {
      try {
        apiData.result.patients = decryptObject(
          apiData.result.patients
        );
        setPatList(apiData.result.patients);
      } catch {
        setPatList([]);
      }
    } else {
      setPatList([]);
    }
  }, [apiData]);

  const sortStrings = (
    aa: string,
    bb: string,
    currDir: SortDirection
  ): number => {
    const val =
      currDir === 'none' ? 0 : currDir === 'ascending' ? 1 : -1;
    return aa > bb ? val : aa < bb ? -val : 0;
  };

  const filterPatientRecord = (pat: PatientRecord): boolean => {
    if (
      (infusionFilter === 'only_na' && pat.lastInfusionDateTime) ||
      (infusionFilter === 'exclude_na' && !pat.lastInfusionDateTime)
    ) {
      return false;
    }
    if (!searchText) {
      return true;
    }
    const targetText =
      pat.lastInfusionDateTime === null
        ? 'N/A'
        : convertDateTime(pat.lastInfusionDateTime ?? '') +
          ' ' +
          pat.encrypt_givenName +
          ' ' +
          pat.encrypt_familyName +
          ' ' +
          pat.encrypt_medicalId;
    const targetLowerCaseText = targetText.toLocaleLowerCase();
    let allFound = true;
    searchText
      .toLowerCase()
      .split(' ')
      .forEach((word) => {
        if (!targetLowerCaseText.includes(word)) {
          allFound = false;
          return;
        }
      });
    return allFound;
  };

  const handleExpand = () => {
    setOpen(!open);
  };

  const handleFilterDateClick = () => {
    if (infusionFilter === 'none') {
      setInfusionFilter('exclude_na');
    } else if (infusionFilter === 'exclude_na') {
      setInfusionFilter('only_na');
    } else {
      setInfusionFilter('none');
    }
  };

  const handleSortFirstNameClick = () => {
    let currDir: SortDirection = sortDirectionFirstName;
    if (currDir === 'none' || currDir === 'descending') {
      currDir = 'ascending';
    } else {
      currDir = 'descending';
    }
    if (patList.length > 1) {
      setPatList((prevPatList) =>
        prevPatList.sort((aa, bb) =>
          sortStrings(
            aa.encrypt_givenName,
            bb.encrypt_givenName,
            currDir
          )
        )
      );
      setSortDirections(currDir, 'none', 'none');
    }
  };

  const handleSortLastNameClick = () => {
    let currDir: SortDirection = sortDirectionLastName;
    if (currDir === 'none' || currDir === 'descending') {
      currDir = 'ascending';
    } else {
      currDir = 'descending';
    }
    if (patList.length > 1) {
      setPatList((prevPatList) =>
        prevPatList.sort((aa, bb) =>
          sortStrings(
            aa.encrypt_familyName,
            bb.encrypt_familyName,
            currDir
          )
        )
      );
      setSortDirections('none', currDir, 'none');
    }
  };

  const handleSortMedicalIdClick = () => {
    let currDir: SortDirection = sortDirectionMedicalId;
    if (currDir === 'none' || currDir === 'descending') {
      currDir = 'ascending';
    } else {
      currDir = 'descending';
    }
    if (patList.length > 1) {
      setPatList((prevPatList) =>
        prevPatList.sort((aa, bb) =>
          sortStrings(
            aa.encrypt_medicalId,
            bb.encrypt_medicalId,
            currDir
          )
        )
      );
      setSortDirections('none', 'none', currDir);
    }
  };

  const setSortDirections = (
    directionFirstName: SortDirection,
    directionLastName: SortDirection,
    directionMedicalId: SortDirection
  ) => {
    setSortDirectionFirstName(directionFirstName);
    setSortDirectionLastName(directionLastName);
    setSortDirectionMedicalId(directionMedicalId);
  };

  const handleResetFiltersClick = () => {
    setSearchText('');
    setInfusionFilter('none');
  };

  const handleOnClickCell = (data: PatientRecord) => {
    setPatientInfo(data);
  };
  const privacyContext = useContext(PrivacyContext);

  return (
    <div className="patient-list-container">
      <div className="container-header">
        <Title>Infusion</Title>
        <ToggleExpand open={open} onClick={handleExpand} />
      </div>
      {open && (
        <div className="list-container">
          <div className="search-header">
            <Search
              onChange={(evt) => setSearchText(evt.target.value)}
              value={searchText}
            />
            <ResetFiltersButton onClick={handleResetFiltersClick} />
          </div>
          <div>
            {/* {theLog && <p>Log: {theLog}</p>} */}
            {isLoading && <p>Loading...</p>}
            {serverError && <p>Error:</p>}
          </div>
          <table className="table-container">
            <Header>
              <HeaderCell>
                <div>recent infusion date</div>
                <FilterButton
                  onClick={handleFilterDateClick}
                  width={24}
                  height={24}
                />
              </HeaderCell>
              <HeaderCell>
                <div>first name</div>
                <SortButton
                  onClick={handleSortFirstNameClick}
                  width={24}
                  height={24}
                  sortDirection={sortDirectionFirstName}
                />
              </HeaderCell>
              <HeaderCell>
                <div>last name</div>
                <SortButton
                  onClick={handleSortLastNameClick}
                  width={24}
                  height={24}
                  sortDirection={sortDirectionLastName}
                />
              </HeaderCell>
              <HeaderCell>
                <div>medical ID</div>
                <SortButton
                  onClick={handleSortMedicalIdClick}
                  width={24}
                  height={24}
                  sortDirection={sortDirectionMedicalId}
                />
              </HeaderCell>
              <HeaderCell>action</HeaderCell>
            </Header>
            <tbody className="body-container">
              {patList.length > 1 &&
                patList
                  .filter(filterPatientRecord)
                  .map((data: PatientRecord, index: number) => (
                    <Row index={index} key={data._id}>
                      <Cell>
                        {data.lastInfusionDateTime === null
                          ? 'N/A'
                          : convertDateTime(
                              data.lastInfusionDateTime ?? ''
                            )}
                      </Cell>
                      <Cell>{data.encrypt_givenName}</Cell>
                      <Cell>{data.encrypt_familyName}</Cell>
                      <Cell>{data.encrypt_medicalId}</Cell>
                      <Cell>
                        <Link
                          to={'/patients/' + data._id}
                          onClick={() => handleOnClickCell(data)}
                        >
                          View Details
                        </Link>
                      </Cell>
                    </Row>
                  ))}
            </tbody>
          </table>
        </div>
      )}
      {privacyContext.active && (
        <Privacy resetPrivacy={privacyContext.resetPrivacy} />
      )}
    </div>
  );
}
