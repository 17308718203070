import { useNavigate, useParams } from 'react-router-dom';
import { FlexContainer } from '../../components/FlexContainer/FlexContainer';
import PatientBox from './PatientBox';
import { PatientInfusion } from './PatientInfusion/PatientInfusion';
import { PatientBloodSamples } from './PatientBloodSamples/PatientBloodSamples';
import { InfusionNotes } from './InfusionNotes/InfusionNotes';
import { Button } from '../../components/Button/Button';
import { AngleLeft } from '../../icons/AngleLeft';
import clsx from "clsx";
import "./Patient.scss"


const Patient = () => {
  const { id } = useParams();
  const patientId = typeof id === 'string' ? id : '';
  const navigate = useNavigate();
  return (
    <FlexContainer direction="column">
      <PatientBox patientId={patientId} />
      <PatientInfusion patientId={patientId} />
      <PatientBloodSamples patientId={patientId} />
      <InfusionNotes patientId={patientId} />
      <div className="patient-controls">
        <Button label={"Back"} className={clsx("go-back-button")} onClick={()=> {navigate('/')}} icon={<AngleLeft />} iconPlacement={"left"}/>
      </div>
    </FlexContainer>
  );
};

export default Patient;
