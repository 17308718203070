import encrypt from './encrypt';
import decrypt from './decrypt';
import { encryptionInterface } from './encryptionInterfaces';

const initVector = '767813924362fb2295eafe9c08e3f4fd';
const Securitykey =
  'd607baffb70227ddcae37db481f11e660f344e5b92ae89697cb55fbce9605112';

export function cipherValues(data: any, cipher: string) {
  if (process.env.TURNOFFENCRYPTION) return;
  for (const key in data) {
    if (data[key] === null || data[key] === undefined) continue;

    if (
      key.indexOf('encrypt_') !== 0 &&
      typeof data[key] === 'object' &&
      !Array.isArray(data[key])
    )
      cipherValues(data[key], cipher);

    if (key.indexOf('encrypt_') !== 0) continue; // If "encrypt_" isn't at the start of the key the value will not be encrypted.

    const encryptionDetails: encryptionInterface = {
      data:
        cipher === 'encrypt' ? JSON.stringify(data[key]) : data[key],
      initVector: initVector,
      Securitykey: Securitykey,
    };

    if (cipher === 'encrypt') {
      data[key] = encrypt(encryptionDetails);
    } else {
      const decryptedValue = decrypt(encryptionDetails);
      data[key] = JSON.parse(decryptedValue);
    }
  }
}

export function encryptObject(dataSetToEncrypt: any) {
  if (Array.isArray(dataSetToEncrypt)) {
    const bindingArray: any = [];
    for (let i = 0; i < dataSetToEncrypt.length; i++) {
      const res = encryptObject(dataSetToEncrypt[i]);
      bindingArray.push(res);
    }
    return bindingArray;
  } else if (typeof dataSetToEncrypt === 'object') {
    cipherValues(dataSetToEncrypt, 'encrypt');
    return dataSetToEncrypt;
  }
}

export function decryptObject(dataSetToDecrypt: any) {
  if (Array.isArray(dataSetToDecrypt)) {
    const bindingArray: any = [];
    for (let i = 0; i < dataSetToDecrypt.length; i++) {
      const res = decryptObject(dataSetToDecrypt[i]);
      bindingArray.push(res);
    }
    return bindingArray;
  } else if (typeof dataSetToDecrypt === 'object') {
    cipherValues(dataSetToDecrypt, 'decrypt');
    return dataSetToDecrypt;
  }
}
