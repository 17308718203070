import clsx from 'clsx';
import { FlexContainer } from '../../../components/FlexContainer/FlexContainer';
import { Card } from '../../../components/Card/Card';
import { CAS_BACK_END_API_URL } from '../../../constants';
import { fetchAuthenticated } from '../../../controllers';
import { useCallback, useContext, useEffect, useState } from 'react';
import './PatientBloodSamples.scss';
import { TextInput } from '../../../components/TextInput/TextInput';
import { Button } from '../../../components/Button/Button';
import { Plus } from '../../../shared/assets/plus';
import { Header } from '../../../components/common/table/Header/Header';
import { HeaderCell } from '../../../components/common/table/HeaderCell/HeaderCell';
import { FilterButton } from '../../../components/common/buttons/iconButtons/FilterButton';
import { SampleHistoryRow } from './SampleHistoryRow/SampleHistoryRow';
import {
  PatientProps,
  TreatmentPlanProps,
  UserProps,
} from '../../../shared/types/types';

import { GlobalUserContext } from '../../../shared/contexts/GlobalUserContext';
import { decryptObject } from 'src/components/Encryption/encryptionHandler';

interface PatientBloodSamplesProps {
  patientId: string;
}

export interface BloodSample {
  _id: string;
  user: UserProps;
  patient: PatientProps;
  treatmentPlan: TreatmentPlanProps;
  createdDateTime: string;
  lastModifiedDateTime: string | null;
  lastModifiedBy: string | null;
  archived: boolean;
  archivedDateTime: string | null;
  archivedBy: string | null;
  encrypt_bloodSampleCollectionId: string; // barcode
  encrypt_bloodSampleCollectionDateTime: string;
  encrypt_result: string;
  encrypt_status: string;
}

export interface BloodSamplesServiceResult {
  status: string;
  total: number;
  items: Array<BloodSample>;
  errors: string[];
}

export interface BloodSamplesAjaxResult {
  result: BloodSamplesServiceResult;
}

export const PatientBloodSamples = ({
  patientId,
}: PatientBloodSamplesProps) => {
  const url =
    CAS_BACK_END_API_URL + `/patients/${patientId}/blood-samples`;
  const [bloodSamples, setBloodSamples] =
    useState<BloodSample[] | null>(null);
  const [barcode, setBarcode] = useState<string>('');
  const [addDisabled, setAddDisabled] = useState<boolean>(true);
  const [status, setStatus] = useState<string | null>(null);
  const { token } = useContext(GlobalUserContext);
  const [expanded, setExpanded] = useState(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiData, setApiData] = useState<BloodSamplesAjaxResult>();
  const [serverError, setServerError] = useState();

  const getBloodSampleData = useCallback(async () => {
    const config: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    setIsLoading(true);
    const response = await fetchAuthenticated<BloodSamplesAjaxResult>(
      url,
      config
    );
    setIsLoading(false);

    if (response.ok) {
      setApiData(response?.parsedBody);
    }
  }, [url, token]);

  useEffect(() => {
    try {
      getBloodSampleData();
    } catch (error: any) {
      setServerError(error);
    }
  }, [getBloodSampleData]);

  useEffect(() => {
    if (apiData?.result?.items) {
      try {
        apiData.result.items = decryptObject(apiData.result.items);
        setBloodSamples(apiData?.result.items);
      } catch {
        setBloodSamples(null);
      }
    }
  }, [apiData]);

  const handleOnExpand = (expanded: boolean) => {
    setExpanded(expanded);
  };

  const AddPatientBloodSample = () => {
    if (barcode !== '') {
      setStatus('Uploading Data');
      setAddDisabled(!addDisabled);
      const body = {
        encrypt_bloodSampleCollectionId: barcode,
        encrypt_bloodSampleCollectionDateTime:
          new Date().toISOString(),
      };

      const config: RequestInit = {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: { Authorization: `Bearer ${token}` },
      };

      const putBloodSampleData = async () => {
        const response = await fetchAuthenticated(url, config); // Sends a put request that uploads new barcode
        if (!response.ok) {
          // share a failed response
          setAddDisabled(true);
          setStatus('Failed Upload');
        } else {
          const res: any = await response.parsedBody;
          setBloodSamples([
            ...(bloodSamples ?? []),
            res?.result?.sample,
          ]);
          setAddDisabled(true);
          setStatus(null);
          setBarcode('');
        }
      };
      putBloodSampleData();
    }
  };

  const body = expanded ? (
    <>
      <FlexContainer direction="column">
        <FlexContainer
          justifyContent="flex-start"
          className="add-blood-sample"
        >
          <div className="blood-sample-input-container">
            <TextInput
              value={status || barcode}
              placeHolder={'Select here then scan vial barcode'}
              onChange={(e) => setBarcode(e.target.value)}
              disabled={!addDisabled}
              width={336}
            />
          </div>
          <div className="add-button-container">
            <Button
              label="Add Blood Sample"
              icon={
                <Plus fill={!addDisabled ? '#C5C5C5' : '#FBFBFB'} />
              }
              iconPlacement="left"
              width="192px"
              onClick={() => AddPatientBloodSample()}
              className={clsx(
                'add-button',
                addDisabled && 'add-button-enabled'
              )}
              disabled={!addDisabled}
            />
          </div>
        </FlexContainer>
        <>
          {isLoading && <p>Loading...</p>}
          {serverError && <p>Error:</p>}
          {bloodSamples && bloodSamples.length > 0 && (
            <FlexContainer direction="column" alignItems="flex-start">
              <table className="table-container">
                <Header>
                  <HeaderCell>
                    <div>draw date</div>
                    <FilterButton
                      onClick={() => {}}
                      width={24}
                      height={24}
                    />
                  </HeaderCell>
                  <HeaderCell>
                    <div>draw time</div>
                    <FilterButton
                      onClick={() => {}}
                      width={24}
                      height={24}
                    />
                  </HeaderCell>
                  <HeaderCell>
                    <div>sample id</div>
                    <FilterButton
                      onClick={() => {}}
                      width={24}
                      height={24}
                    />
                  </HeaderCell>
                  <HeaderCell>
                    <div>result</div>
                    <FilterButton
                      onClick={() => {}}
                      width={24}
                      height={24}
                    />
                  </HeaderCell>
                  <HeaderCell>
                    <div>last updated</div>
                    <FilterButton
                      onClick={() => {}}
                      width={24}
                      height={24}
                    />
                  </HeaderCell>
                  <HeaderCell align="center">action</HeaderCell>
                </Header>
                <tbody className="body-container">
                  {bloodSamples.map((sample, index) => (
                    <SampleHistoryRow
                      sample={sample}
                      index={index}
                      patientId={patientId}
                      key={sample._id}
                    />
                  ))}
                </tbody>
              </table>
            </FlexContainer>
          )}
        </>
      </FlexContainer>
    </>
  ) : (
    <></>
  );

  return (
    <div
      className="patient-blood-sample-container"
      data-testid="patient-blood-samples-card"
    >
      <Card
        title={'BLOOD SAMPLES'}
        body={body}
        expandable
        expanded={expanded}
        onExpand={handleOnExpand}
      />
    </div>
  );
};
