import React from 'react';
import { SearchButton } from '../buttons/iconButtons/SearchButton';
import './Search.scss';

export interface SearchProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
}

export function Search({
  onChange,
  onKeyDown,
  value,
  ...rest
}: SearchProps) {
  return (
    <div className={`search-container`} {...rest}>
      <input
        className="search-input"
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Search"
        value={value}
      />
      <SearchButton width={16} height={16} />
    </div>
  );
}
