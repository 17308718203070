import { useCallback, useContext, useEffect, useState } from 'react';
import { fetchAuthenticated } from '../../../controllers';
import { CAS_BACK_END_API_URL } from '../../../constants';
import { PatientProps, UserProps } from 'src/shared/types/types';
import { Card } from '../../../components/Card/Card';
import {
  convertDate,
  convertTime,
  convertDateTime,
} from '../../../components/common/convertDateTime/convertDateTime';
import './InfusionNotes.scss';
import { AddNote } from './AddNote/AddNote';
import { InfusionNoteDTO } from '.';
import { InfusionNote } from './InfusionNote';
import { GlobalUserContext } from '../../../shared/contexts/GlobalUserContext';
import { decryptObject } from 'src/components/Encryption/encryptionHandler';

interface InfusionNoteData {
  _id: string;
  user: UserProps;
  patient: PatientProps;
  createdDateTime: string;
  lastModifiedDateTime: string | null;
  lastModifiedBy: string | null; // user ID
  archived: boolean;
  archivedDateTime: string | null;
  archivedBy: string | null; // user ID
  encrypt_note: string;
  type: string;
}

interface GetInfusionNotesResponse {
  status: string;
  total: number;
  // items: WithId<Document>[] | InfusionNote[]; // todo what about WithId?
  items: InfusionNoteData[];
  errors: string[];
}

interface InfusionNotesAjaxResult {
  result: GetInfusionNotesResponse;
}

interface InfusionNotesProps {
  patientId: string;
}

export const InfusionNotes = ({ patientId }: InfusionNotesProps) => {
  const { token } = useContext(GlobalUserContext);
  const [infusionNotes, setInfusionNotes] = useState<
    InfusionNoteDTO[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiData, setApiData] = useState<InfusionNotesAjaxResult>();
  const [serverError, setServerError] = useState();

  const infusionNotesURL =
    CAS_BACK_END_API_URL +
    '/patients/' +
    patientId +
    '/infusions/notes';

  const getInfusionNotes = useCallback(async () => {
    const configGet: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    setIsLoading(true);
    const response =
      await fetchAuthenticated<InfusionNotesAjaxResult>(
        infusionNotesURL,
        configGet
      );
    setIsLoading(false);

    if (response.ok) {
      setApiData(response.parsedBody);
    }
  }, [infusionNotesURL, token]);

  useEffect(() => {
    try {
      getInfusionNotes();
    } catch (error: any) {
      setServerError(error);
    }
  }, [getInfusionNotes]);

  const [isLoadingNewNote, setIsLoadingNewNote] =
    useState<boolean>(false);

  const [isLoadingEditNote, setIsLoadingEditNote] =
    useState<boolean>(false);

  const [expanded, setExpanded] = useState(true);

  const handleOnExpand = (expanded: boolean) => {
    setExpanded(expanded);
  };
  useEffect(() => {
    if (apiData?.result?.items) {
      try {
        apiData.result.items = decryptObject(apiData.result.items);
        setInfusionNotes(
          apiData.result.items.map((item) => {
            return {
              key: item._id,
              author: item.user.name,
              date: convertDate(item.createdDateTime),
              time: convertTime(item.createdDateTime),
              encrypt_note: item.encrypt_note,
              editedDateTime: item?.lastModifiedDateTime
                ? convertDateTime(item.lastModifiedDateTime)
                : null,
            };
          })
        );
      } catch {
        setInfusionNotes([]);
      }
    }
  }, [apiData]);

  interface PutNoteResponse {
    result: {
      note: {
        _id: string;
        user: {
          name: string;
        };
        createdDateTime: string;
        lastModifiedDateTime: string;
        encrypt_note: string;
      };
    };
  }

  const handleAddNoteSubmit = (
    noteBody: string
  ): Promise<boolean> => {
    // we return a promise to help our AddNoteComponent clear the Wysiwyg Editor's state if the submission process succeeds

    setIsLoadingNewNote(true);

    return new Promise<boolean>(async (resolve, reject) => {
      const response = await fetchAuthenticated<PutNoteResponse>(
        infusionNotesURL,
        {
          method: 'PUT',
          body: JSON.stringify({ encrypt_note: noteBody }),
          // Auth headers are manually entered for now, fetchAuthenticated will soon be updated to add them implicitly.
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setIsLoadingNewNote(false);
      if (!response.ok) reject('Note did not post');
      if (response.parsedBody === undefined) return;

      const data = response.parsedBody;
      const item = data.result.note;
      const newNote = {
        key: item._id,
        author: item.user.name,
        date: convertDate(item.createdDateTime),
        time: convertTime(item.createdDateTime),
        editedDateTime: item?.lastModifiedDateTime
          ? convertDateTime(item.lastModifiedDateTime)
          : null,
        encrypt_note: item.encrypt_note,
      };
      const newNotes = [...infusionNotes, newNote];
      setInfusionNotes(newNotes);
      resolve(true);
    });
  };

  const handleEditNoteSubmit = (
    noteId: string,
    noteBody: string
  ): Promise<boolean> => {
    const infusionNoteURL = `${CAS_BACK_END_API_URL}/patients/${patientId}/infusions/notes/${noteId}`;
    setIsLoadingEditNote(true);

    return new Promise<boolean>(async (resolve, reject) => {
      const response = await fetchAuthenticated(infusionNoteURL, {
        method: 'PATCH',
        body: JSON.stringify({ encrypt_note: noteBody }),
        // Auth headers are manually entered for now, fetchAuthenticated will soon be updated to add them implicitly.
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsLoadingEditNote(false);
      if (!response.ok) reject('Note did not post');
      // update the existing note list with new note data
      setInfusionNotes(
        infusionNotes.map((note) => {
          if (note.key !== noteId) return note;

          const newEditTime = new Date().toISOString();
          return {
            ...note,
            body: noteBody,
            editedDateTime: newEditTime,
          };
        })
      );
      resolve(true);
    });
  };

  const handleDeleteNoteSubmit = (
    noteId: string
  ): Promise<boolean> => {
    const infusionNoteURL = `${CAS_BACK_END_API_URL}/patients/${patientId}/infusions/notes/${noteId}`;

    return new Promise<boolean>(async (resolve, reject) => {
      const response = await fetchAuthenticated(infusionNoteURL, {
        method: 'DELETE',
        // Auth headers are manually entered for now, fetchAuthenticated will soon be updated to add them implicitly.
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      try {
        if (!response.ok) reject();
        setInfusionNotes(
          infusionNotes.filter((note: InfusionNoteDTO) => {
            return note.key !== noteId;
          })
        );
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  };

  const body = expanded ? (
    <>
      {isLoading && <p>Loading ...</p>}
      {serverError && <p>Error:</p>} {/* TODO: add error text */}
      {!isLoading && !serverError && (
        <div className="notes-container">
          <div className="notes-list">
            {infusionNotes.map((item: InfusionNoteDTO) => {
              return (
                <InfusionNote
                  key={item.key}
                  data={{
                    key: item.key,
                    author: item.author,
                    date: item.date,
                    time: item.time,
                    editedDateTime: item.editedDateTime,
                    encrypt_note: item.encrypt_note,
                  }}
                  isLoadingEditNote={isLoadingEditNote}
                  onNoteEdit={handleEditNoteSubmit}
                  onNoteDelete={handleDeleteNoteSubmit}
                />
              );
            })}
          </div>
          <AddNote
            isLoadingNewNote={isLoadingNewNote}
            onSubmit={handleAddNoteSubmit}
          />
        </div>
      )}
    </>
  ) : (
    <></>
  );

  return (
    <div className="notes-card">
      <Card
        alignTitle="start"
        title={'Infusion Notes'}
        body={body}
        expandable
        expanded={expanded}
        onExpand={handleOnExpand}
      />
    </div>
  );
};
