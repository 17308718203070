import { useEffect,  useState } from "react";
import { CarrotDown } from "../../shared/assets/carrot-down";
import { CloudIcon } from "../../shared/assets/cloud-icon-green";
import { SyringeIcon } from "../../shared/assets/syringe-teal";
import "./Navbar.scss";
import { format } from "date-fns";
export interface NavbarProps {
  label?: string;
}

const Navbar = ({ ...props }: NavbarProps) => {
  
  const dayMonthYear = format(new Date(), 'dd-LLL-yyyy');
  const hoursMinsSeconds = format(new Date(), 'HH:mm:ss'); 
  // eslint-disable-next-line
  const [time, setTime] = useState<number>(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div data-testid='navbar-container' className='navbar-container'>
      <div className='navbar-item nav-dropdown'>
        <SyringeIcon fill='#0091b2' />
        <span className='infusion-dropdown'>Infusion</span>
        <CarrotDown />
      </div>
      <div className='navbar-item'>
        <CloudIcon />
        <span className='nbs-status'>NBS: Ready</span>
      </div>
      <div className="navbar-item">{dayMonthYear}</div>
      <div className="navbar-item time-container">
        <span className="current-time">{hoursMinsSeconds}</span>
      </div>
    </div>
  );
};

export default Navbar;
