import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const domain = process.env.REACT_APP_AUTH0_DOMAIN
  ? process.env.REACT_APP_AUTH0_DOMAIN
  : '';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  ? process.env.REACT_APP_AUTH0_CLIENT_ID
  : '';

root.render(
  <Router>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </Router>
);
